<template>
	<div id="Introduction">
		<!-- 项目介绍 -->
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>项目介绍</p>
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="content">
			<ul v-if="menus">
				<li v-for="(item,i) in menus" :key="i" @click="menusActive(item.menuName)"
					:class="active == item.menuName ? 'active':''">
					<i>“</i>
					{{item.menuName}}
				</li>
				<!-- <router-link :to="{name: 'introduce'}" tag="li" exact>
					<i>“</i>
					<a>项目介绍</a>
				</router-link>
				<router-link :to="{name: 'flowPath'}" tag="li">
					<i>“</i>
					<a>参与流程</a>
				</router-link>
				<router-link :to="{name: 'condition'}" tag="li">
					<i>“</i>
					<a>投稿要求</a>
				</router-link>
				 <router-link :to="{name: 'statement'}" tag="li">
				 	<i>“</i>
				 	<a>特别声明</a>
				 </router-link>-->
			</ul>
			<router-view class="view" />
		</div>
	</div>
</template>

<script>
	import {
		ProjectPage, // 优秀稿件
		getConfig, // 首页获取logo banner 流程图  
	} from '@/request/api';
	export default {
		name: 'Introduction',
		components: {},
		data() {
			return {
				active: '项目介绍',
				menus: [],
				banner: ''
			}
		},
		created() {
			this.getConfigFun();
			this.getProjectPage();
		},
		mounted() {},
		methods: {
			menusActive(active) {
				this.active = active;
				if (active == '项目介绍') {
					this.$router.push({
						name: 'introduce'
					})
				} else if (active == '参与流程') {
					this.$router.push({
						name: 'flowPath'
					})
				} else if (active == '投稿要求') {
					this.$router.push({
						name: 'condition'
					})
				} else if (active == '特别声明') {
					this.$router.push({
						name: 'statement'
					})
				}
			},

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			},

			getProjectPage() {
				ProjectPage().then(res => {
					if (res.code == 200) {
						this.menus = res.data.menus
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#Introduction {
		display: flex;
		flex-direction: column;

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.content {
			display: flex;
			flex-direction: row;
			width: 1200px;
			margin: auto;
			padding-top: 30px;

			ul {
				display: flex;
				flex-direction: column;
				margin-right: 120px;

				* {
					cursor: pointer;
				}

				li {
					display: flex;
					flex-direction: row;
					font-size: 36px;
					align-items: flex-start;
					margin-bottom: 70px;

					a {
						display: block;
					}

					i {
						font-style: normal;
						font-size: 60px;
						color: rgba(203, 50, 44, 1);
						line-height: 1;
					}
				}

				.active {

					color: rgba(203, 50, 44, 1);
					transition: all .3s;
				}
			}

			.view {
				width: 900px;
				overflow: hidden;
				flex: 1;
				align-items: flex-start;
				min-height: 100px;
			}
		}
	}
</style>